<template>
  <div class="container-fluid mb-20" v-loading.fullscreen.lock="loading">
    <el-row class="artist-list mt-2 mb-10" :gutter="0">
      <h2 class="artist-head-title ms-12">AI Artists</h2>
      <el-col :span="24">
        <el-row :gutter="24">
          <el-col
            :xl="4"
            :lg="6"
            :md="6"
            :sm="8"
            v-for="(artist, key) in mongoArtists"
            v-bind:key="key"
          >
            <el-card
              class="box-card aside-dark"
              @click="routeToArtistDetailPageV2(artist, 'aiArtists')"
            >
              <div class="text-center">
                <el-avatar :size="150" :src="artist.imageUrl"></el-avatar>
                <h3 class="artist-name truncate">
                  {{ artist.name.toUpperCase() }}
                </h3>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="artist-list mt-2 mb-10" :gutter="0">
      <h2 class="artist-head-title ms-12">Live Data</h2>
      <el-col :span="24">
        <el-row :gutter="24">
          <el-col
            :xl="4"
            :lg="6"
            :md="6"
            :sm="8"
            v-for="(artist, key) in artists"
            v-bind:key="key"
          >
            <el-card
              class="box-card aside-dark"
              @click="routeToArtistDetailPageV2(artist, 'spotifyArtists')"
            >
              <div class="text-center">
                <el-avatar
                  :size="150"
                  :src="artistImage(artist.images)"
                ></el-avatar>
                <h3 class="artist-name truncate">
                  {{ artist.name.toUpperCase() }}
                </h3>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { getArtistUUIDBySpotifyID } from "@/api/mongoDB";
import {
  increaseUserSearchCountV2,
  saveMongoArtistDetail,
} from "@/api/user.api";
export default {
  name: "ArtistListV2",
  data() {
    return {
      loading: false,
      store: useStore(),
    };
  },
  props: {
    artists: {
      type: Array,
      required: true,
    },
    mongoArtists: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async routeToArtistDetailPageV2(artist, type) {
      try {
        this.loading = true;
        let spotifyID = "";
        if (type === "aiArtists") {
          await this.store.dispatch(
            "ArtistModule/setArtistUUID",
            artist.soundchartsUUID
          );
          // saveMongoArtistDetail(artist.spotifyID);
          spotifyID = artist.spotifyID;
          window.open(`/ai-artist/${artist.spotifyID}`, "_blank");
        } else if (type === "spotifyArtists") {
          const { data } = await getArtistUUIDBySpotifyID(artist.id);
          await this.store.dispatch("ArtistModule/setArtistUUID", data);
          spotifyID = artist.id;
          saveMongoArtistDetail(artist.id);
          window.open(`/ai-artist/${artist.id}`, "_blank");
        }
        await increaseUserSearchCountV2(spotifyID);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    artistImage(images) {
      if (images.length > 0) {
        return images[0].url;
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.artist-head-title {
  margin-top: 24px;
  font-weight: 700;
  color: #33647f;
  font-size: 20px;
}
.artist-name {
  color: #33647f;
}
.box-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
</style>
