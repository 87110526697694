<template>
  <div class="artist-container" v-loading.fullscreen.lock="loading">
    <ArtistListV2 :artists="artists" :mongoArtists="mongoArtists" />
  </div>
</template>

<script>
import ArtistListV2 from "../views/ArtistListV2.vue";
import { getArtistByName, getArtistsBySpotifySearch } from "@/api/mongoDB";

export default {
  name: "ArtistsSearchV2",
  components: {
    ArtistListV2,
  },
  data() {
    return {
      artists: [],
      mongoArtists: [],
      term: "",
      loading: false,
    };
  },
  async mounted() {
    if (this.$route.query.search) {
      this.$store.dispatch(
        "EventsModule/searchArtist",
        this.$route.query.search
      );
      await this.getArtists(this.$route.query.search);
    }
  },
  computed: {
    searchQuery() {
      return this.$route.query.search;
    },
  },
  watch: {
    async searchQuery(newValue) {
      this.loading = true;
      await this.getArtists(newValue);
      this.loading = false;
    },
  },
  methods: {
    async getArtists(searchedArtist) {
      if (searchedArtist !== null && searchedArtist !== "") {
        try {
          this.loading = true;
          const { data } = await getArtistsBySpotifySearch(searchedArtist);
          this.artists = data ? data : [];
          const mongoData = await getArtistByName(searchedArtist);
          this.mongoArtists = mongoData.data;
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      } else {
        this.artists = [];
        this.mongoArtists = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.artist-container {
  margin-top: 4em !important;
}
</style>
